import {createSlice, PayloadAction} from '@reduxjs/toolkit'



type MapItemData = {
    isActive: number[]
}

const initialState: MapItemData = {
    isActive: []
}

const mapSlice = createSlice({
    name: "mapSlice",
    initialState: initialState,
    reducers:{
        handleActiveItem: (state, action: PayloadAction<number>)=>{
            if(state.isActive.includes(action.payload)){
               state.isActive = state.isActive.filter(d=> d !== action.payload)
            }else{
                state.isActive = [...state.isActive, action.payload]
            }
        },
    }
})

export const {handleActiveItem} = mapSlice.actions
export default mapSlice.reducer