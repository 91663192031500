import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from 'lodash'
import { GeofenceProp } from '../../pages/map/useMapContext'
import { stat } from 'fs'

type Geoshape = {
    activeItems: any[],
    ids: string[]
    jsonData: GeofenceProp
}
const initialState:Geoshape = {
    activeItems:  [],
    ids: [],
    jsonData: {} as GeofenceProp
}

const geofenceSlice = createSlice({
    name: "geofenceSlice",
    initialState: initialState,
    reducers: {

        // ectractGeodata: ()=>{

        // },
        handleSelectItem: (state,  action:PayloadAction<any>)=>{
            let item = _.find(state.activeItems, action.payload)
            if(item){
                console.log("Checking...")
                state.activeItems = state.activeItems.filter(d=>d?.properties?.name !== action.payload?.properties?.name)
            }else {
                state.activeItems = [...state.activeItems, action.payload]
                state.ids= [...state.ids, action.payload?._id]
            }
        },

        editItem:(state, action:PayloadAction<any>)=>{
            state.jsonData = action.payload
        },
       getIds: (state,  action:PayloadAction<any>)=>{
            let id = state.ids.includes(action.payload)
            console.log("insiede reducer", action.payload)

            if(id){
                console.log("Checking...")
                state.ids = state.ids.filter(d => d !== action.payload)
               
            }else {
                state.ids= [...state.ids, action.payload]
            }
        },
        deleteItem: (state,  action:PayloadAction<any>)=>{
            let item = action?.payload?.geoData
            state.activeItems = state.activeItems.filter(d=>d?.properties?.name !== item?.properties?.name)
            state.ids = state.ids.filter(d => d !== action.payload?._id)
        },
    }
})

export const {handleSelectItem, editItem, getIds, deleteItem} = geofenceSlice.actions
export default geofenceSlice.reducer
