import React, {
  useState,
  createContext,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';
import { IconType } from 'react-icons/lib';
import { useQueryClient } from 'react-query';
import L from 'leaflet';
import { InitialState, ActionType } from '../../../features/geofenceReducer';
import AWS from 'aws-sdk';
import { AwsCtx } from './AwsContext';
import { useAppSelector } from '../../../reducers/disPatchSelector';

/***********************GEOFENCE CONTEXT***********************************************/
type GeoContext = {
  geo: boolean;
};

type SetGeoContxt = {
  setGeo: React.Dispatch<SetStateAction<boolean>>;
};

type GeofenceCtxs = GeoContext & SetGeoContxt;
type CtxProps = {
  children: React.ReactNode;
};

/* Creating a context with a type of `GeofenceCtxs | undefined` and setting the default value to
`undefined` */
const GeofenceCtx = createContext<GeofenceCtxs | undefined>(undefined);

const initialState: InitialState = {
  showGeo: false,
  componentType: '',
};

type NewAction = {
  dispatch: React.Dispatch<ActionType>;
};

//Geofence user context
type NewCtx = InitialState & NewAction;
const CtxNew = createContext<NewCtx | undefined>(undefined);

//Draw map context
type ShapeType = {
  shape: string;
};

type ShapeString = {
  setShape: React.Dispatch<SetStateAction<string>>;
};

/***********************MapItem TopRight Items CONTEXT***********************************************/

type MapItem = {
  showItem?: boolean;
  comp: string;
};

type MapItemAction = {
  setShowItem: React.Dispatch<SetStateAction<MapItem>>;
};

const mapItems: MapItem = {
  showItem: false,
  comp: '',
};

type MapItemType = MapItem & MapItemAction;

const MapItemCtx = createContext<MapItemType>({} as MapItemType);

type MapType = ShapeType & ShapeString;
const MapCtx = createContext<MapType | undefined>(undefined);

/***********************POI CONTEXT***********************************************/
type IconProp = {
  icon: IconType | null;
};

type POIProps = {
  icn: IconProp;
  setIcon: React.Dispatch<SetStateAction<IconProp>>;
};

const POICtx = createContext<POIProps>({} as POIProps);

/***********************Login CONTEXT***********************************************/
// type Login = {
//     state: "idle" | "loading" | "loading" | "success"
// }

type LoginFunc = {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
};

const LoginCtx = createContext<LoginFunc>({} as LoginFunc);

/***********************POI Display CONTEXT***********************************************/
type DisplayPoi = {
  poi: boolean;
  showPoi: React.Dispatch<React.SetStateAction<boolean>>;
};

const PoiDisplayCtx = createContext<DisplayPoi>({} as DisplayPoi);

/***********************POI FORM CONTEXT and POI DATA***********************************************/
export type POIData = {
  description: string;
  label: string;
  lat: number;
  lon: number;
  iconTag?: string;
  customIcon?: string;
};

export const pois: POIData = {
  description: '',
  label: '',
  lat: 0,
  lon: 0,
  iconTag: '',
  customIcon: '',
};

type POICtxAction = {
  poiData: POIData;
  setPoiData: React.Dispatch<React.SetStateAction<POIData>>;
};
type ActivePoi = {
  lat: number;
  lng: number;
  icon: string;
  desc?: any;
}[];
type POIActiveData = {
  activePoi: ActivePoi;
  ids: string[];
  setActivePoi: React.Dispatch<React.SetStateAction<ActivePoi>>;
  setPoiId: React.Dispatch<React.SetStateAction<string[]>>;
  editPoiId: string;
  setEditPoi: React.Dispatch<React.SetStateAction<string>>;
};

export const POIFormCtx = createContext<POICtxAction>({} as POICtxAction);
export const POIActiveCtx = createContext<POIActiveData>({} as POIActiveData);

/***********************SET DEVICE FORM CONTEXT***********************************************/
export type DevicesData = {
  sensorsEnabled: string[];
  users: string[];
  user: string;
  deviceName: string;
  deviceModel: string;
  deviceType: string;
  phone: string;
  imei: string;
  simiccid: string;
  icon: string;
};
type SetDeviceTyped = {
  devices: DevicesData;
  setDevices: React.Dispatch<React.SetStateAction<DevicesData>>;
};

const SetDeviceCtx = createContext<SetDeviceTyped>({} as SetDeviceTyped);
export const devicesData = {
  sensorsEnabled: [],
  users: [],
  user: '63a2d074dcf4ecf313fb9e41',
  deviceName: '',
  deviceModel: '',
  deviceType: '',
  phone: '',
  imei: '',
  simiccid: '',
  icon: '',
};

/***********************Device Tracking FORM CONTEXT***********************************************/

//Get ans set all the device id
type TrackingFn = {
  deviceId: string[];
  setDeviceId: React.Dispatch<React.SetStateAction<string[]>>;
};

// Get and display all device
type ActiveDevice = {
  activeDevice: Array<any>;
  setActiveDevice: React.Dispatch<React.SetStateAction<Array<any>>>;
};

type FilterDevice = {
  filterDevice: Array<any>;
  setFilterDevice: React.Dispatch<React.SetStateAction<Array<any>>>;
};

type DevicePanel = {
  showPanel: boolean;
  deviceSummary: any;
};
type MapBottomPanel = {
  panel: DevicePanel;
  setPanel: React.Dispatch<React.SetStateAction<DevicePanel>>;
};

const MapBottomCtx = createContext<MapBottomPanel>({} as MapBottomPanel);
const DeviceTrackingCtx = createContext<TrackingFn>({} as TrackingFn);
const ActiveDeviceCtx = createContext<ActiveDevice>({} as ActiveDevice);
const FilterDeviceCtx = createContext<FilterDevice>({} as FilterDevice);

/***********************POPUP Modal context***********************************************/
type PopModal = {
  modalPop: string;
  setModalPop: React.Dispatch<React.SetStateAction<string>>;
};

type ToggleModal = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopModalCtx = createContext<PopModal>({} as PopModal);
const ToggleModalCtx = createContext<ToggleModal>({} as ToggleModal);

/********************SUBMIT CONTEXT***********************************************/

type DataState = 'idle' | 'loading' | 'success' | 'failed';
type SubmitContex = {
  isSubmitting: boolean;
  message: string;
  dataState: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setDataState: React.Dispatch<React.SetStateAction<string>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
};

const SubmitCtx = createContext<SubmitContex>({} as SubmitContex);

/***********************************2D CanvasItem***********************************************/
type Canvas2D = {
  canvasId: { [name: string]: any };
  setCanvas: React.Dispatch<React.SetStateAction<{ [name: string]: any }>>;
  canvas: Array<{ [name: string]: any }>;
  setCanvasObj: React.Dispatch<React.SetStateAction<{ [name: string]: any }[]>>;
  enableEdit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  index: string;
  setIndex: React.Dispatch<React.SetStateAction<string>>;
  initPos: { [k: string]: any }[];
  setInitPos: React.Dispatch<React.SetStateAction<{ [k: string]: any }[]>>;
};

const CanvasCtx = createContext<Canvas2D>({} as Canvas2D);

/***********************Roles and Permission Context***********************************************/
type RolePermission = {
  userRole: { [k: string]: any };
  setUserRole: React.Dispatch<React.SetStateAction<{ [k: string]: any }>>;
};

const RolePermitCtx = createContext<RolePermission>({} as RolePermission);

/***********************************Global App State***********************************************/
// type AppState = {
//     isSubmitting: boolean
//     setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
//     serverMsg: string,
//     setServerMsg: React.Dispatch<React.SetStateAction<string>>
//     dataState: string,
//     setDataState: React.Dispatch<React.SetStateAction<string>>
// }

// export const AppStateCtx = createContext<AppState>({} as AppState)

/***********************************Global Context Provider***********************************************/
export const GeoProvider = ({ children }: CtxProps) => {
  //Geofence state
  const [geo, setGeo] = useState(true);

  //User state with actions
  //const [state, dispatch] = useReducer(geofenceReducer, initialState );
  const [showItems, setShowItem] = useState(mapItems);

  // Login state Action
  const [state, setState] = useState('idle');

  //Map state
  const [shape, setShape] = useState('');

  //POI Icon State
  const item: IconProp = {
    icon: null,
  };
  const [icn, setIcon] = useState(item);

  //Display POI
  const [poi, showPoi] = useState(false);

  //POI Form data
  const [poiData, setPoiData] = useState<POIData>(pois);
  const [activePoi, setActivePoi] = useState<ActivePoi>([]);
  const [ids, setPoiId] = useState<string[]>([]);
  const [editPoiId, setEditPoi] = useState('');

  //SetDevice Form Data
  const user = useAppSelector(
    (state) => state.userReducer.currentUser?.body?.data?._id
  );
  const [devices, setDevices] = useState<DevicesData>(devicesData);

  //Tracking Device State
  const [deviceId, setDeviceId] = useState<string[]>([]);
  const [activeDevice, setActiveDevice] = useState<any | []>([]);
  const [filterDevice, setFilterDevice] = useState<any[]>([]);

  //Map Bottom Device Panel
  const panelData = {
    showPanel: false,
    deviceSummary: {},
  };
  const [panel, setPanel] = useState<DevicePanel>(panelData);

  //Pop Modal state and Toggle Modal
  const [modalPop, setModalPop] = useState('');
  const [show, setShow] = useState(false);

  // Submtting Context Satte
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [dataState, setDataState] = useState('idle');

  //AWS client configuration
  const endpoint = new AWS.Endpoint('f2w3.or.idrivee2-37.com');
  const s3 = new AWS.S3({
    endpoint: endpoint,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY!,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY!,
    },
  });

  //Canvas State set
  const canvase = {
    canvasId: {},
  };
  //Item select on canvas
  const [canvasId, setCanvas] = useState<{ [name: string]: any }>(canvase);
  //Items on canvas
  const [canvas, setCanvasObj] = useState<{ [name: string]: any }[]>([]);
  //Keep track of edit
  const [enableEdit, setEdit] = useState(false);
  //Keep track of index selected on canvas
  const [index, setIndex] = useState('');
  //Keep track of initial Items
  const [initPos, setInitPos] = useState<{ [k: string]: any }[]>([]);

  // Role and Permission state
  const roles: { [k: string]: any } = {
    title: '',
    history: [],
    permissions: [],
  };
  const [userRole, setUserRole] = useState(roles);

  return (
    <GeofenceCtx.Provider value={{ geo, setGeo }}>
      <MapItemCtx.Provider value={{ ...showItems, setShowItem }}>
        <MapCtx.Provider value={{ shape, setShape }}>
          <POICtx.Provider value={{ icn, setIcon }}>
            <LoginCtx.Provider value={{ state, setState }}>
              <PoiDisplayCtx.Provider value={{ poi, showPoi }}>
                <POIFormCtx.Provider value={{ poiData, setPoiData }}>
                  <SetDeviceCtx.Provider value={{ devices, setDevices }}>
                    <DeviceTrackingCtx.Provider
                      value={{ deviceId, setDeviceId }}
                    >
                      <FilterDeviceCtx.Provider
                        value={{ filterDevice, setFilterDevice }}
                      >
                        <ActiveDeviceCtx.Provider
                          value={{ activeDevice, setActiveDevice }}
                        >
                          <PopModalCtx.Provider
                            value={{ modalPop, setModalPop }}
                          >
                            <ToggleModalCtx.Provider value={{ show, setShow }}>
                              <MapBottomCtx.Provider
                                value={{ panel, setPanel }}
                              >
                                <POIActiveCtx.Provider
                                  value={{
                                    activePoi,
                                    ids,
                                    editPoiId,
                                    setActivePoi,
                                    setPoiId,
                                    setEditPoi,
                                  }}
                                >
                                  <SubmitCtx.Provider
                                    value={{
                                      isSubmitting,
                                      message,
                                      dataState,
                                      setMessage,
                                      setDataState,
                                      setIsSubmitting,
                                    }}
                                  >
                                    <AwsCtx.Provider value={{ s3 }}>
                                      <CanvasCtx.Provider
                                        value={{
                                          canvasId,
                                          index,
                                          initPos,
                                          canvas,
                                          enableEdit,
                                          setIndex,
                                          setInitPos,
                                          setEdit,
                                          setCanvasObj,
                                          setCanvas,
                                        }}
                                      >
                                        <RolePermitCtx.Provider
                                          value={{ userRole, setUserRole }}
                                        >
                                          {children}
                                        </RolePermitCtx.Provider>
                                      </CanvasCtx.Provider>
                                    </AwsCtx.Provider>
                                  </SubmitCtx.Provider>
                                </POIActiveCtx.Provider>
                              </MapBottomCtx.Provider>
                            </ToggleModalCtx.Provider>
                          </PopModalCtx.Provider>
                        </ActiveDeviceCtx.Provider>
                      </FilterDeviceCtx.Provider>
                    </DeviceTrackingCtx.Provider>
                  </SetDeviceCtx.Provider>
                </POIFormCtx.Provider>
              </PoiDisplayCtx.Provider>
            </LoginCtx.Provider>
          </POICtx.Provider>
        </MapCtx.Provider>
      </MapItemCtx.Provider>
    </GeofenceCtx.Provider>
  );
};

// export const useGeoUser = ()=>{
//     const {dispatch, ...state} = useContext(CtxNew) as NewCtx;

//     if(state === null){
//         throw new Error("state is empty")
//     }
//     return {dispatch, ...state}; //Destructring object directly in array required adding new object in array
// }

export const useNewGeoUser = () => {
  const { setShowItem, ...showItems } = useContext(MapItemCtx) as MapItemType;
  if (showItems === null) {
    throw new Error('state is empty');
  }
  return { setShowItem, ...showItems };
};

/**
 * This function is a hook that returns the geofence context and the setGeofence function from the
 * context provider.
 * @returns The geo and setGeo are being returned.
 */
export const useGeofence = () => {
  const { geo, setGeo } = useContext(GeofenceCtx) as GeofenceCtxs;
  if (!setGeo) {
    throw new Error('The Provider is missing');
  }

  return [geo, setGeo];
};

export const useGeofenceShape = () => {
  const { shape, setShape } = useContext(MapCtx) as MapType;

  return { shape, setShape };
};

export const usePOIcon = () => {
  const { icn, setIcon } = useContext(POICtx);

  return { icn, setIcon };
};

export const useLoginState = () => {
  const { state, setState } = useContext(LoginCtx);
  return { state, setState };
};

export const usePoIFormItem = () => {
  const { poiData, setPoiData } = useContext(POIFormCtx);

  return { poiData, setPoiData };
};

export const usePoiDisplay = () => {
  const { poi, showPoi } = useContext(PoiDisplayCtx);
  const { activePoi, setActivePoi, ids, setPoiId, setEditPoi, editPoiId } =
    useContext(POIActiveCtx);

  const handleActivePoi = ({
    lat,
    lng,
    icon,
    id,
    desc,
  }: {
    lat: number;
    lng: number;
    id: string;
    icon: string;
    desc?: any;
  }) => {
    const checkId = ids.includes(id);
    if (checkId) {
      let filterPoi = activePoi?.filter(
        (item) => !(item.lat === lat && item.lng === lng)
      );
      let filterIds = ids.filter((p) => p !== id);
      setPoiId(filterIds);
      setActivePoi(filterPoi);
    } else {
      setPoiId((prev) => {
        return [...prev, id];
      });
      setActivePoi((prev) => {
        return [...prev, { lat, lng, icon, desc }];
      });
    }
  };
  const handlePoiDisplay = (arg: boolean) => {
    showPoi(arg);
  };

  return {
    poi,
    activePoi,
    setEditPoi,
    handlePoiDisplay,
    handleActivePoi,
    ids,
    editPoiId,
  };
};

//Use submitting hooks
export const useSubmitting = () => {
  const {
    isSubmitting,
    setIsSubmitting,
    message,
    setMessage,
    dataState,
    setDataState,
  } = useContext(SubmitCtx);

  return {
    isSubmitting,
    setIsSubmitting,
    message,
    setMessage,
    dataState,
    setDataState,
  };
};

//Use Set Device Form
export const useSetDevice = () => {
  const { devices, setDevices } = useContext(SetDeviceCtx);

  return { devices, setDevices };
};

//Use Modal Pop func
export const usePopModal = () => {
  const { modalPop, setModalPop } = useContext(PopModalCtx);
  const { show, setShow } = useContext(ToggleModalCtx);
  const toggleModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setShow((prev) => !prev);
  };

  const handlePopModal = () => {
    setShow(!show);
  };
  return { modalPop, setModalPop, setShow, show, toggleModal, handlePopModal };
};

//Use Tracking Device state
export const useDeviceTracking = () => {
  const { deviceId, setDeviceId } = useContext(DeviceTrackingCtx);
  const { activeDevice, setActiveDevice } = useContext(ActiveDeviceCtx);
  const { filterDevice, setFilterDevice } = useContext(FilterDeviceCtx);
  const queryClient = useQueryClient();
  const devices: any = queryClient.getQueryData('devices');
  const { panel, setPanel } = useContext(MapBottomCtx);

  useEffect(() => {
    setFilterDevice(devices?.data?.data);
  }, [setFilterDevice, devices?.data?.data]);

  //const [activeDevice, setActiveDevice] = useState<any>([])

  const searchItem = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value) {
      const items: any[] = filterDevice?.filter(
        (d: any) =>
          d.deviceName.toLowerCase().includes(value.toLowerCase()) ||
          d.imei.includes(value)
      );

      if (items.length > 0) setFilterDevice(items);
    } else {
      setFilterDevice(devices?.data?.data);
    }
  };

  const getTrackingPanel = (id: string) => {
    let item = devices?.data?.data.find((item: any) => item._id === id);
    setPanel({
      showPanel: true,
      deviceSummary: item,
    });
  };
  const handleTrackingDevice = (e: React.ChangeEvent<HTMLInputElement>) => {
    let id = e.target.value;
    setDeviceId((prevSelected: string[]) => {
      if (id === 'all') return [];
      // if it's in, remove
      const newArray: string[] = [...prevSelected];
      if (newArray.includes(id)) {
        return newArray.filter((item: string) => item !== id);
        // else, add
      } else {
        newArray.push(id);
        return newArray;
      }
    });

    if (deviceId && deviceId.includes(e.target.value) && !e.target.checked) {
      setActiveDevice((prev: any) => {
        return prev?.filter((d: any) => d._id !== id);
      });
    } else {
      let newItem = devices?.data?.data.filter((d: any) => d._id === id);
      setActiveDevice((prev) => {
        return [...prev, ...newItem];
      });
    }

    if (e.target.value === 'all') {
      let newItem = devices?.data?.data;
      setActiveDevice((prev) => {
        if (e.target.checked) {
          return newItem;
        } else {
          return [];
        }
      });
    }
  };

  return {
    activeDevice,
    setActiveDevice,
    handleTrackingDevice,
    getTrackingPanel,
    searchItem,
    filterDevice,
    panel,
  };
};

//Use canvas item display
export const useCanvasDisplay = () => {
  const {
    canvasId,
    setCanvas,
    canvas,
    enableEdit,
    setEdit,
    setCanvasObj,
    index,
    setIndex,
    initPos,
    setInitPos,
  } = useContext(CanvasCtx);

  const url = process.env.REACT_APP_MONITOR_URL + 'canvas';

  const bounds = L.latLngBounds(L.latLng(0, 0), L.latLng(1000, 1500));

  //Set the item chose
  const handleCanvasItem = (item: typeof canvasId) => {
    setCanvas(item);
    setCanvasObj(item?.devicePositions);
    setInitPos(item?.devicePositions); //Keep a reference to old item
  };

  const toggleEdit = () => setEdit((prev) => !prev);

  const resetMarker = () => {
    toggleEdit();

    let update = initPos?.filter((d) => d?.deviceId === index);

    setCanvasObj((prev) => {
      return [...prev.filter((d) => d?.deviceId !== index), ...update];
    });
  };

  const handleMarkerDrag = (e: any, id: string) => {
    setIndex(id);
    let newPosition = e.target.getLatLng();

    // Check if the new position is within the image bounds
    if (!bounds.contains(newPosition)) {
      // Calculate the closest point within the bounds
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      newPosition = new L.LatLng(
        Math.max(sw.lat, Math.min(ne.lat, newPosition.lat + 100)),
        Math.max(sw.lng, Math.min(ne.lng, newPosition.lng + 300))
      );
      e.target.setLatLng(newPosition);
    }
    setCanvasObj((prev) => {
      let update = prev.filter((d) => d?.deviceId !== id);
      let newItem = [
        ...update,
        { deviceId: id, xPos: newPosition.lat, yPos: newPosition.lng },
      ];
      setCanvas((prev) => {
        return {
          ...prev,
          devicePositions: newItem,
        };
      });
      return newItem;
    });
  };

  return {
    handleCanvasItem,
    canvasId,
    handleMarkerDrag,
    canvas,
    bounds,
    enableEdit,
    toggleEdit,
    setEdit,
    resetMarker,
  };
};

// UseRole hooks
export const useRoleCtx = () => {
  return useContext(RolePermitCtx);
};
