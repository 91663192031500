import { createContext, useContext, useState } from "react"
import { PromiseResult } from 'aws-sdk/lib/request';
import { useSubmitting } from "./useGeofenceContext";

type AwsS3 = {
    s3 : AWS.S3
}

export const AwsCtx = createContext<AwsS3>({} as AwsS3)


export const useAws = ()=>{
    const {s3} = useContext(AwsCtx)
    const [,setDataState] = useState('idle')
    //const {setDataState} = useSubmitting()
    const uploadFile = async (file:{[name: string]: any}) => {
       
        // S3 Bucket Name
        const S3_BUCKET = "gentro-uploads";
    
        
        // Files Parameters
    
        const params = {
          Bucket: S3_BUCKET,
          Key: file?.name,
          Body: file,
        };
    
        // Uploading file to s3
    
        var upload:Promise<PromiseResult<AWS.S3.PutObjectOutput, AWS.AWSError>> = s3
          .putObject(params)
          .on("httpUploadProgress", (evt:{[name:string]:any}) => {
            setDataState("loading")
            // File uploading progress
            console.log(
              "Uploading " + String((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();
        

        await upload.then(({$response:res})=>{
            //console.log("response",res.data)
            if(res.data) setDataState('success');
        }).catch(err=>{
            setDataState('failed')
            console.log(err.message)
        })
      };

      return {uploadFile}
}