import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //Transaltion file path
    backend:{
      loadPath:"/assets/langs/i18n/{{ns}}/{{lng}}.json",
    },

    //use when no languaugew is Available
    fallbackLng: "en",
    
    debug: false,

    //enable multiple name spaces and transaltions into small pieces and 
    //load them on demand
    ns: ["common"],

    interpolation:{
      escapeValue: false,
      formatSeparator: ","
    },

    react:{
      wait:true,
    },

  });

export default i18n;
