import styled from 'styled-components/macro'
import { Column } from '../../stylesExtend/globalStyles'

export const ExpirationContainer = styled(Column)`
    justify-content: center;
    align-items: center;
    gap: 20px;

    img{
        width: 150px;
        height: 150px;
        object-fit: cover;
    }

    span{
        color:  #505050;
        font-family: 'Inter';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
    }

    button{
        width: 120px;
        border: none;
        color: #fff;
        padding: 15px 30px;
        border-radius: 6px;
        background: #4D9200;
        box-shadow: 0px 0px 0px 1px #4D9200, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
        margin-top: 4rem;
        font-weight: 500;
    }
`
