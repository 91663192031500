import { DefaultTheme } from "styled-components";

//Creating a theme from default

const gentTheme: DefaultTheme = {
    colors: {
        // main: "blue",
        // secondary: "red"
        body: "#fff"
    },
}

export {gentTheme}