import { ReactNode, createContext, useContext, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import { getToken } from '../hooks/getToken';
import { useAppSelector } from '../reducers/disPatchSelector';

export type SocketProps = {
  socket: Socket;
};

export const SocketCtx = createContext<SocketProps>({} as SocketProps);

const SocketProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAppSelector((state) => state.userReducer);
  const socket = io('https://websocket.server.gentro.io', {
    secure: true,
    rejectUnauthorized: false,
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    },
  });

  useEffect(() => {
    if (auth.isAuth && !auth.error) {
      let emissionInterval: ReturnType<typeof setInterval>;

      socket.on('connect', () => {
        if (emissionInterval) {
          clearInterval(emissionInterval);
        }

        // Emit data to the server every 20 seconds
        emissionInterval = setInterval(() => {
          const end = Date.now();
          const start = end - 20000;

          const message = { startTime: start, endTime: end };

          socket.emit('records', message);
        }, 20000); // 20 seconds (20000 milliseconds)
      });

      socket.on('error', (err: any) => {
        console.log(err);
      });

      socket.on('open', (msg: any) => {});

      socket.on('error', (msg: any) => {
        //console.log(socket.connected)
      });
    }
  }, [socket, auth]);

  return <SocketCtx.Provider value={{ socket }}>{children}</SocketCtx.Provider>;
};

export default SocketProvider;
