import styled, { createGlobalStyle } from 'styled-components/macro'

const GlobalStyles = createGlobalStyle`
  :root{
    --size-divisor: 1;
  }

  * {
    --tooltip-text-color: white;
    --tooltip-background-color: black;
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 6px;
    --font-size: 1rem;
    --outline: red;
    --primary-bg: #fff;
    --item-bg: #4D9200;
    --input-bg: #F3F4F6;
    --letter-spacing: 0.2px;
    --line-height: 32px;
    --shadow: 0px 0px 0px 1px rgba(152, 161, 179, 0.1), 
              0px 15px 35px -5px rgba(17, 24, 38, 0.15), 
              0px 5px 15px rgba(0, 0, 0, 0.08);
    letter-spacing: var(--letter-spacing);
    -webkit-letter-spacing: var(--letter-spacing);
    -moz-letter-spacing: var(--letter-spacing);
    -ms-letter-spacing: var(--letter-spacing);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    /* color: #505050; */
  }

  body {
    //background: ${({ theme }) => theme.colors.body};
    opacity: 0.9;
    background:  #E9EDF5;
    color: hsl(192, 100%, 9%);
    font-size: 1rem;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    //overflow-y: scroll;
    box-sizing: border-box;
    //min-height: 50rem;

    &::-webkit-scrollbar{
        background: #fff;
        width: 8px;
    }
   
    &::-webkit-scrollbar-thumb {
        background: #7DE495;
        border-radius: 20px;
    }

    [data-title]:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.5s;
      visibility: visible;
    }
    [data-title]:after {
        content: attr(data-title);
        position: absolute;
        bottom: -1.6em;
        left: 100%;
        padding: 4px 4px 4px 8px;
        color: #222;
        white-space: nowrap; 
        -moz-border-radius: 5px; 
        -webkit-border-radius: 5px;  
        border-radius: 5px;  
        -moz-box-shadow: 0px 0px 4px #222;  
        -webkit-box-shadow: 0px 0px 4px #222;  
        box-shadow: 0px 0px 4px #222;  
        background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
        background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
        background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);  
        background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
        background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);  
        background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
        opacity: 0;
        z-index: 99999;
        visibility: hidden;
    }
    [data-title] {
        position: relative;
    }
  }

  @media only screen and (min-width: 250px) and (max-width: 570px) {
  :root{
    --size-divisor: 2.3;
  }
}

  @media only screen and (min-width: 576px) and (max-width: 765px) {
  :root{
    --size-divisor: 2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  :root{
    --size-divisor: 1.7;
  }
}

  @media only screen and (min-width: 992px) and (max-width: 1350px){
    :root{
      --size-divisor: 1;
    }
  }

  @media (min-width: 1400px) {
    :root{
      --size-divisor: .95;
    }
  }


  //Css Components class global class

  .marker__icon{
    width: 12px;
    height: 12px;
    aspect-ratio: 1;
    background: #FFFFFF;
    box-shadow: 
            0px 1px 1px rgba(0, 0, 0, 0.1), 
            0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 4px;
  }

  .modal__form__btn{
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: #4D9200;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #4D9200;
    border-radius: 6px;
    border: none;
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #fff;
  }

  .rounded, .rounded-dark{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    background-color: var(--item-bg);
    width: 1.1rem;
    height: 1.1rem;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .rounded-dark{
    background-color: #505050;
  }

  .text-1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 25px;
    letter-spacing: 0.02px;
    color: #000;
  }

  .text-2, .text-3, .text-4, .text-5{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    letter-spacing: 0.02px;
    display: inline-flex;
    align-items: center;
    color: #505050;
  }

  .text-3{
    font-weight: 300;
    font-family: 'Roboto';
    font-size: 0.8rem;
  }

  .text-4{
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 0.75rem;
  }

  .text-5{
    font-weight: 300;
    font-family: 'Inter';
    font-size: 0.6rem;
    line-height: 10px;
  }

  .text-6{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #000;
  }

  .text-7, .text-8{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
  }

  .text-8{
    font-size: 0.8rem;
    color: #505050;
  }

  .pl-0{
    padding-left: 1rem;
  }
  .pl-1{
    padding-left: 3rem;
  }

  .pl-2{
    padding-left: 2.1rem;
  }
  .pl-3{
    padding-left: 3.5rem;
  }
  .pl-4{
    padding-left: 1.3rem;
  }

  .pd-1{
      padding: 10px 20px;
  }

  .pb-0{
    padding-bottom: 0.7rem;
  }
  .pb-1{
    padding-bottom: 0.3rem;
  }


  .pt-0{
    padding-top: 1rem;
  }


  .bd-0{
    border: none;
  }
  .border-b{
    border-bottom: 1px solid #E9EDF5;
  }

`

export const GlobalContainer = styled.div`
  width: 100vw;
  box-sizing: border-box;
  /* max-width:100%; */
  font-size: var(--font-size);
`

export const Row = styled.div`
  display: flex;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text400 = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  color: #505050;
`
export const Text500 = styled(Text400)`
  font-weight: 500;
`
export const Text600 = styled(Text400)`
  font-weight: 600;
`
export const Text700 = styled(Text400)`
  font-weight: 700;
`

export default GlobalStyles