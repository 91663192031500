import { combineReducers } from '@reduxjs/toolkit'
import userReducer from './login/loginSlice'
import mapReducer from './slices/mapSlice'
import geofenceReducer from './geofence/geofenceSlice'
import canvasReducer from './2dcanvas/canvasSlice'
import poiReducer from './slices/poiSlice'


const rootReducer = combineReducers({
    userReducer,
    mapReducer,
    geofenceReducer,
    canvasReducer,
    poiReducer
})

export default rootReducer