import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type InitialState = {
    title: string,
    image: string,
    devices: string[],
    devicePositions: {[name:string]: any}[]
}
const initialState:InitialState = {
    title: "",
    image: "",
    devices:[],
    devicePositions: []
}

const canvasSlice = createSlice({
    name: "canvasSlice",
    initialState: initialState,
    reducers:{
        createCanvas:(state, actions:PayloadAction<{[name:string]: any}>)=>{
            state.devices.push(...actions.payload?.devices)
            state.title = actions.payload?.title
            state.image = actions.payload.image
            
        },

        updateDevicePosition:(state, actions)=>{
           
            
        },

        removeCanvas: (state)=>{
            state.title = ""
            state.devices= []
            state.image = ""
            state.devicePositions = []
        }
    }
})

export const {createCanvas, removeCanvas, updateDevicePosition} = canvasSlice.actions
export default canvasSlice.reducer