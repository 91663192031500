import React from 'react'
import { ExpirationContainer } from './token.styled'
import { useDispatch } from 'react-redux'
import { logOutSuccess } from '../../reducers/login/loginSlice'

type Prop = {
  handleModal:()=>void
}
const TokenExpiration = ({handleModal}:Prop) => {

  const dispatch = useDispatch()
  const handleToken = ()=>{
    dispatch(logOutSuccess())
    handleModal()
  }
  return (
    <ExpirationContainer>
        <img src={process.env.PUBLIC_URL+"/assets/images/failed.png"} alt='delete png'/>
        <span>Token has expired, login again</span>
        <button onClick={handleToken}>Log out</button>
    </ExpirationContainer>
  )
}

export default TokenExpiration