import React, { 
    useState, 
    createContext, 
    SetStateAction, 
    useRef,
    useContext, 
    useReducer, 
    useEffect,
    useCallback
} from "react";
import L from 'leaflet';
import { useAppSelector } from "../../reducers/disPatchSelector";
import { usePopModal } from "../../components/selectRef/hook/useGeofenceContext";

type GeoJsonData={
  "type": "Feature",
  "properties"?: object,
  "geometry":{
    "type": "Polygon" | "Point",
    "coordinates": any[]
  }

} 

export type GeofenceProp={
  color?:string,
  name:string,
  label:string,
  latlng:any[] ,
  radius?: number,
  layer?: object,
  geoData: GeoJsonData 
}

type MapContextProps={
    mapRef:React.MutableRefObject<L.Map | null>,
    drawItem:React.MutableRefObject<L.FeatureGroup>,
    drawHandlerRef:React.MutableRefObject<L.Draw.Polygon | null>
    drawCircleRef:React.MutableRefObject<L.Draw.Circle | null>
    editHandlerRef:React.MutableRefObject<L.EditToolbar.Edit | null>
    layer:L.Layer | null
    setLayer:React.Dispatch<SetStateAction<L.Layer|null>>,
    editEnabled:boolean,
    setEditEnabled:React.Dispatch<SetStateAction<boolean>>,
    geofence:GeofenceProp
    setGeofence:React.Dispatch<SetStateAction<GeofenceProp>>
}

const MapContext = createContext<MapContextProps>({} as MapContextProps)
export  const initGeoData:GeofenceProp = {
  name:"", 
  label:"",
  latlng:[], 
  color: "#4D9200",
  geoData: {
    type:"Feature", properties: {}, 
    geometry:{
      type: "Polygon", 
      coordinates: []
    }
  }
}

export const MapContextProvider = ({children}:{children:React.ReactNode})=>{
 
  const mapRef = useRef<L.Map | null>(null);
  const drawItem = useRef<L.FeatureGroup>(new L.FeatureGroup());
  const [editEnabled, setEditEnabled] = useState(false);
  const [layer, setLayer] = useState<L.Layer | null>(null);
  const drawHandlerRef = useRef<L.Draw.Polygon| null>(null);
  const drawCircleRef = useRef<L.Draw.Circle | null>(null);
  const editHandlerRef = useRef<L.EditToolbar.Edit | null>(null)
  const [geofence,setGeofence] = useState<GeofenceProp>(initGeoData)
 


  return(
    <MapContext.Provider value={{mapRef, drawItem, drawHandlerRef,drawCircleRef, layer, setLayer, editEnabled, setEditEnabled, editHandlerRef,geofence,setGeofence}}>
        {children}
    </MapContext.Provider>
  )
}

export const useMapCtx = ()=>{
    const {mapRef,editEnabled, layer, drawItem, geofence, setLayer, drawHandlerRef,drawCircleRef,editHandlerRef,setGeofence, setEditEnabled} = useContext(MapContext)
    const active:string[] = useAppSelector(state=> state.geofenceReducer.activeItems)
    const {modalPop} = usePopModal()
    
    // mapRef.current?.fitBounds([[4.4767865, 7846578]])
    useEffect(()=>{
        const removeCtr = mapRef?.current as L.Map
        //mapRef.current?.setView()
       
       
       //Set the map raw options
        const drawOptions = {
          edit:{
            featureGroup:drawItem.current as L.FeatureGroup,
            edit:false,
            remove:false
          },
          draw: false
        } as unknown as L.Control.DrawConstructorOptions;
       

        //Enable the control function on the map
        const drawControl = new L.Control.Draw(drawOptions);
        removeCtr?.addControl(drawControl);
        console.log("run",removeCtr)

        

        //mapRef.current?.addLayer(drawItem.current)

        //Listen to the draw event on the map
        //create the shape with it options
        mapRef.current?.on(L.Draw.Event.EDITED, (e:any)=>{
          const layer = (e as L.DrawEvents.Edited)
          const features = layer.layers.toGeoJSON() as any
        
          // editHandlerRef.current?.disable()
         
         
         
          if(features.features[0].geometry.type === 'Polygon'){
              console.log("edit stop",features?.features)
             
            setGeofence((prev:GeofenceProp)=>{
              return{
                ...prev,
                label:"polygon",
                latlng:[features.features[0].geometry.coordinates],
                geoData: features.features[0]
              }
            })
          }
        })
        mapRef.current?.on(L.Draw.Event.CREATED,(e:any)=>{
          const layer = (e as L.DrawEvents.Created)
          console.log("geojson",layer.layer.toGeoJSON())
          drawItem.current.addLayer(layer.layer);
          mapRef.current?.addLayer(layer.layer)
          if(layer.layer.toGeoJSON().geometry.type === 'Polygon'){
            console.log("cordi", layer.layer.toGeoJSON().geometry.coordinates)
            setGeofence((prev:GeofenceProp)=>{
              return{
                ...prev,
                label:"polygon",
                latlng:[layer.layer.toGeoJSON().geometry.coordinates],
                geoData: {
                  type: "Feature",
                  properties : {...prev.geoData.properties},
                  geometry:{
                    type: "Polygon",
                    coordinates: layer.layer.toGeoJSON().geometry.coordinates
                  }
                }
              }
            })
            
          }

          if(layer.layer.toGeoJSON().geometry.type === 'Point'){
            console.log("cordi", layer.layer.toGeoJSON().geometry.coordinates)
            let layerRadius = layer.layer?.options as any
            console.log("cordieerrt", layerRadius.radius)
            setGeofence((prev:GeofenceProp)=>{
              return{
                ...prev,
                label:"circle",
                latlng:layer.layer.toGeoJSON().geometry.coordinates,
                radius: layerRadius?.radius,
                geoData: {
                  type: "Feature",
                  properties : {
                    ...prev.geoData.properties,
                    radius: layerRadius.radius
                  },
                  geometry:{
                    type: "Point",
                    coordinates: layer.layer.toGeoJSON().geometry.coordinates
                  }
                }
              }
            })
            // console.log("polygon")
          }
          // editHandlerRef.current?.enable()
          setLayer(layer.layer)

        })


        return()=>{
          console.log("ctr remove")
          removeCtr?.removeControl(drawControl)
          // removeCtr?.remove()
        }
      
      },[mapRef,drawItem,setLayer,editHandlerRef,setGeofence, editEnabled])
    
      //Draw polygon 
    const handleDrawButtonClick = useCallback(() => {
        if (mapRef.current) {
        
            console.log("darw",drawItem.current.getLayers())
            console.log("layer",layer)
            if(drawItem.current.getLayers().length > 0 && layer){
             
              mapRef.current?.removeLayer(layer as L.Layer)
              drawItem.current.removeLayer(layer as L.Layer)
              mapRef.current.addLayer(drawItem.current)
              setLayer(null)
              drawHandlerRef?.current?.enable()
            
            }else{

              drawHandlerRef.current = new L.Draw.Polygon(mapRef.current as L.DrawMap, {
                allowIntersection: false,
                showArea: true,
                repeatMode:false,
                 shapeOptions:{
                  color: geofence.color ,
                  fillColor:geofence.color,
                }
            });
                drawHandlerRef?.current?.enable();
                
                //toggleEdit();
        
                //setLayer(null)
                
            }
        }
    },[drawHandlerRef,drawItem,layer,mapRef,setLayer, geofence.color]);

    //Draw Circle
    const handleDrawCircleClick = useCallback(() => {
      // drawItem.current.clearLayers()
      if (mapRef.current) {
          drawCircleRef.current = new L.Draw.Circle(mapRef.current as L.DrawMap,{
            repeatMode:false,
            shapeOptions:{
              color: geofence.color,
              fillColor:geofence.color,
            }
          });
        
          //console.log(layer)
          if(layer){
            setLayer(null)
            drawCircleRef?.current?.enable();
          }else{
           
            drawCircleRef?.current?.enable()
          }
      }
  },[drawCircleRef,layer,mapRef,setLayer, geofence.color]);

  //Reset the map layer
  const clearLayer = useCallback(()=>{
  
   if(mapRef.current && active.length > 0){
  
    drawItem.current.clearLayers()
   }

  },[mapRef, drawItem, active.length])
    
    

  //Choose the shape to draw
    const handleDrawShape=useCallback((shape:string)=>{
      // drawItem.current.clearAllEventListeners()
      //drawItem.current.clearLayers()
      if(shape === "polygon"){
        handleDrawButtonClick();
      }else {
        // drawItem.current.clearLayers()
        handleDrawCircleClick();
      }
    },[handleDrawButtonClick, handleDrawCircleClick])

    const handleEditButtonClick = useCallback(() => {
      console.log("every check", editEnabled)
      if(editEnabled){
        setEditEnabled(prev=>!prev)
        editHandlerRef.current?.disable()
        editHandlerRef.current = null
        console.log("hummm", editEnabled)
         
        
      }else{
        if(modalPop === "Edit Geo" && drawItem.current instanceof L.FeatureGroup){

          console.log("eddiddddd")
          editHandlerRef.current = new L.EditToolbar.Edit(mapRef.current as L.DrawMap,{
            featureGroup: drawItem.current as L.FeatureGroup,
            poly:{
             
              allowIntersection: false
            }
          }) 
          console.log("hummmnooo", editEnabled)
          editHandlerRef.current?.enable()
          setEditEnabled(prev=>!prev)
        }
        
      }
      
    }, [editHandlerRef, editEnabled, setEditEnabled, mapRef, drawItem, modalPop]);

    const saveLayer = useCallback(()=>{
     
      if(editEnabled){
        setEditEnabled(prev=>!prev)
        editHandlerRef.current?.save()
        editHandlerRef.current?.disable()
        //editHandlerRef.current = null
       
      }
    
     
    }, [editEnabled, editHandlerRef, setEditEnabled])


    return {
      handleDrawButtonClick,
      handleDrawCircleClick,
      handleDrawShape,
      handleEditButtonClick,
      setGeofence, 
      geofence,
      mapRef, 
      drawItem,
      editHandlerRef,
      layer,
      setLayer,
      setEditEnabled,
      saveLayer,
      clearLayer
    }
}

